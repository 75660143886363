<template>
  <div class="row">
    <ValidationProvider
      v-for="(i, id) in items"
      :key="id"
      tag="div"
      :rules="{
        more_then: id === 0 ? filter[max] : false,
        less_then: id === 1 ? filter[min] : false,
      }"
      v-slot="{ errors }"
    >
      <BaseNumberInput
        class="form-control--simple"
        :placeholder="i.title"
        :validateError="errors[0]"
        :symbol="symbol"
        v-model="filter[i.key]"
        :element="filter[i.key]"
        :measurement="measurement"
        @input="filterChange({ i, event: $event })"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    symbol: { type: String },
    name: { type: String },
    min: { type: String },
    max: { type: String },
    measurement: {type: String, required: false}
  },
  components: {
    ValidationProvider,
    BaseNumberInput,
  },
  data() {
    return {
      filter: {
        [this.min]: "",
        [this.max]: "",
      },
    };
  },
  watch: {
    items: {
      handler(val) {
        val.forEach(element => {
          if (this.filter[element.key]) {
            this.filter[element.key] = element.val || "";
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    async filterChange({ i, event }) {
      this.filter[i.key] = event;
      let valid = this.filterValidation();
      this.$emit("selectFilter", {
        key: i.key,
        filter: i,
        val: event,
        valid,
      });
    },
    filterValidation() {
      let valid = false;
      if (Number(this.filter[this.max]) && Number(this.filter[this.min])) {
        valid = Number(this.filter[this.min]) < Number(this.filter[this.max]) ? true : false;
      } else valid = true;

      return valid;
    },
  },
  created() {
    this.filter[this.min] = "";
    this.filter[this.max] = "";
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  div {
    flex: 1;
    margin-top: 0;
  }
}
</style>
