<template>
  <div class="filter">
    <header class="filter__header">
      <IconButton
        class="button--full-width button--public button--public-right"
        @click="() => changeFilter(true)"
        text="Filter"
      >
        <template #right><IconFilter></IconFilter></template>
      </IconButton>
    </header>
    <transition name="filter__fade">
      <div class="filter__body-overlay" v-show="open">
        <div class="filter__body" id="scroll-block">
          <div class="filter__body_header">
            <p class="text">Filters</p>
            <div class="filter__body_header_right">
              <BaseButton
                class="button--only-text button--only-text-underline"
                @click="$emit('clear')"
                :disabled="!selectedFiltersNumbers"
                text="Clear filters"
              ></BaseButton>
              <IconButton
                class="button button--xlg button--outline-white"
                @click="() => changeFilter(false)"
              >
                <IconClose width="18"></IconClose>
              </IconButton>
            </div>
          </div>
          <div class="filter__body_search">
            <BaseInput
              class="search__input form-control--black"
              :element="search"
              :icon="true"
              placeholder="Type to search"
              @input="event => changeInput(event)"
              @keyup.enter.native="inputSearch"
            >
              <template #icon>
                <IconSearch color="#fff" height="21" width="21"></IconSearch> </template
            ></BaseInput>
          </div>

          <div class="filter__body_results">
            <div class="filter__body_results-values">
              <template v-for="item in selectedFilters">
                <Tag
                  class="tag--public tag--public-outline"
                  :class="{
                    'tag--periods': item.key === 'periods',
                  }"
                  v-for="(i, idx) in item.items"
                  :key="`${i.title}-${idx}`"
                  :value="
                    item.key === 'price' ||
                    item.key === 'width' ||
                    item.key === 'height' ||
                    item.key === 'depth'
                      ? `${i.title} ${item.key}: ${i.val}`
                      : i.title
                  "
                  :close="true"
                  @click="$emit('selectFilter', { key: item.key, filter: i, apply: true })"
                />
              </template>
            </div>
            <p>Applied Filters</p>
          </div>

          <ValidationObserver ref="observer" tag="div" class="filter__body_content">
            <div v-for="item in items" :key="item.group">
              <div
                class="filter__group"
                v-if="
                  item.type != 'invisible' &&
                  ((searchValue && item.items.length && item.type != 'range') ||
                    (!searchValue && item.items.length))
                "
              >
                <div class="filter__group_header" @click="item.open = !item.open">
                  <p class="text">{{ item.group }}</p>
                  <span class="icon" :class="{ 'icon--down': item.open }"
                    ><IconArrowTop width="10" height="6"></IconArrowTop
                  ></span>
                </div>
                <div
                  class="filter__group_body filter__group_body--open-xl"
                  :class="{ 'filter__group_body--open': item.open }"
                >
                  <div class="filter__group_body-content">
                    <template v-if="item.type === 'range'">
                      <template v-if="item.key === 'sizes'">
                        <div class="filter__group_body-content_body">
                          <p class="filter__group_body-content_subtitle">Width</p>
                          <FilterMinMax
                            :items="items[correctEl('width')].items"
                            name="Width"
                            min="width_start"
                            max="width_end"
                            :measurement="'“'"
                            @selectFilter="selectFilter"
                          />
                        </div>
                        <div class="filter__group_body-content_body">
                          <p class="filter__group_body-content_subtitle">Height</p>
                          <FilterMinMax
                            :items="items[correctEl('height')].items"
                            name="Height"
                            min="height_start"
                            max="height_end"
                            :measurement="'“'"
                            @selectFilter="selectFilter"
                          />
                        </div>
                        <div class="filter__group_body-content_body">
                          <p class="filter__group_body-content_subtitle">Depth</p>
                          <FilterMinMax
                            :items="items[correctEl('depth')].items"
                            name="Depth"
                            min="depth_start"
                            max="depth_end"
                            :measurement="'“'"
                            @selectFilter="selectFilter"
                          />
                        </div>
                      </template>

                      <FilterMinMax
                        v-else
                        :items="item.items"
                        :name="item.group"
                        :min="`${item.key}_start`"
                        :max="`${item.key}_end`"
                        :symbol="item.key === 'price' ? '$' : ''"
                        @selectFilter="selectFilter"
                      />
                    </template>

                    <div class="filter__group_body-content_checkboxes" v-else>
                      <FilterContent
                        :item="item"
                        :isCollapsible="item.key === 'materials' || item.key === 'colors'"
                        @selectFilter="val => $emit('selectFilter', val)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="!isFilters">No results found for your search</p>
          </ValidationObserver>
          <div>
            <BaseButton
              class="button--public button--full-width button--white button--uppercase"
              text="apply filter"
              @click="applyFilter"
            ></BaseButton>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import IconButton from "@/components/buttons/IconButton.vue";
import TogglePlusMinus from "@/components/buttons/TogglePlusMinus.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconFilter from "@/components/icons/IconFilterPublic.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconSearch from "@/components/icons/IconSearch.vue";

import Tag from "@/components/Tag.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import ClickOutside from "vue-click-outside";
import { mapMutations, mapGetters } from "vuex";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import FilterContent from "./FilterContent.vue";
import FilterMinMax from "./FilterMinMax.vue";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    search: { type: String },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    IconButton,
    TogglePlusMinus,
    BaseButton,
    IconFilter,
    Tag,
    IconClose,
    IconSearch,
    BaseNumberInput,
    IconArrowTop,
    BaseInput,
    BaseCheckbox,
    FilterContent,
    FilterMinMax,
  },
  data() {
    return {
      open: false,
      searchValue: "",
      price: {
        price_start: "",
        price_end: "",
      },
      width: {
        width_start: "",
        width_end: "",
      },
      height: {
        height_start: "",
        height_end: "",
      },
      depth: {
        depth_start: "",
        depth_end: "",
      },
      isFilters: false,
    };
  },
  computed: {
    ...mapGetters("publicapi/filters", {
      selectedFiltersNumbers: "getSelectedFiltersNumbers",
      selectedFilters: "getSelectedFilters",
    }),
  },
  watch: {
    open(val) {
      this.$emit("open", val);
      const values = ["price" || "width" || "height" || "depth"];

      this.items.forEach(el => {
        if (values.includes(el.key)) {
          el.items.forEach(element => {
            this[el.key][element.key] = element.selected ? element.val : "";
          });
        }
      });
    },
    items: {
      handler: function (val) {
        this.baseSetup(val);
        this.isFilters = false;
        val.forEach(element => {
          if (
            (element.key !== "price" ||
              element.key !== "width" ||
              element.key !== "height" ||
              element.key !== "depth") &&
            element.items.length > 0
          ) {
            this.isFilters = true;
            return;
          }
        });
      },
      deep: true,
    },
    search: {
      handler: function (val) {
        if (val != this.searchValue) {
          this.searchValue = val;
        }
      },
      deep: true,
    },
  },
  unmounted() {
    clearTimeout(this.timer);
  },
  methods: {
    ...mapMutations(["setIsFilter"]),
    correctEl(name) {
      const i = this.items.findIndex(el => el.key === name);
      return i;
    },
    changeInput(event) {
      this.searchValue = event;
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.$emit("changeSearch", event);
      }, 300);
    },
    baseSetup(val) {
      const values = ["price" || "width" || "height" || "depth"];
      val.forEach(el => {
        if (values.includes(el.key)) {
          el.items.forEach(element => {
            this[el.key][element.key] = element.val;
          });
        }
      });
    },
    selectFilter(value) {
      this.$emit("selectFilter", value);
    },
    changeFilter(val) {
      this.open = val;
      this.setIsFilter(val);
    },
    applyFilter() {
      this.changeFilter(false);
      this.$emit("apply");
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.filter {
  position: relative;
  margin: 5px 0;
  &__fade {
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }
    &-enter {
      opacity: 0;
      .filter__body {
        transform: translateX(100%);
      }
      &-to {
        opacity: 1;
        .filter__body {
          transform: translateX(0%);
        }
      }
    }
    &-leave {
      opacity: 1;
      .filter__body {
        transform: translateX(0%);
      }
      &-to {
        opacity: 0;
        .filter__body {
          transform: translateX(100%);
        }
      }
    }
  }
  .text {
    @include publictext($fs: 18px, $fw: 400, $lh: 1.15);
    color: $white;
    @media only screen and (max-width: $lg) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    max-width: 500px;
    height: 100vh;
    background-color: #111;
    padding: 24px;
    transition: all 1s ease;
    @media screen and (max-width: $xs) {
      min-width: 86vw;
    }
    &-overlay {
      position: fixed;
      display: flex;
      justify-content: flex-end;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      z-index: 1000;
      background: rgba($color: $lightBlack, $alpha: 0.1);
      @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        backdrop-filter: blur(8px);
      }
      @supports not (backdrop-filter: none) {
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba($color: $lightBlack, $alpha: 0.3);
          filter: blur(8px);
          z-index: -1;
        }
      }
    }
    &_content {
      overflow-y: auto;
      height: 100%;
      padding-bottom: 15px;
      & > div + p {
        display: none;
      }
      & > div:empty {
        & + p {
          display: inline-flex;
          padding-top: 10px;
        }
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;
      .text {
        font-size: 24px;
      }
      &_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }
    }
    &_search {
      margin-bottom: 16px;
    }
    &_results {
      display: flex;
      flex-direction: column-reverse;
      gap: 8px;
      margin-bottom: 2px;
      &-values {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px 4px;
        padding-bottom: 16px;
        &:empty {
          & + p {
            display: none;
          }
        }
      }
      p {
        width: 100%;
        font-size: 14px;
        line-height: 1.14;
      }
    }
  }
  &__group {
    border-bottom: 0.5px solid #707070;
    padding-bottom: 20px;
    &--buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px;
      margin: auto 0 0;
      .button {
        margin: 0 1.1rem 10px;
        @media screen and (max-width: $xs) {
          width: 100%;
        }
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0 0;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 9px;
          transform: rotate(180deg);
          ::v-deep g {
            fill: $white;
            transition: all 0.3s ease-in-out;
          }
        }
        &--down {
          transform: rotate(0deg);
        }
      }
    }
    &_body {
      max-height: 0;
      overflow-y: auto;
      transition: all 0.3s ease;
      &-content {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 0;
        // @media screen and (max-width: $xs) {
        //   padding: 10px 30px 15px 10px;
        // }
        &_checkboxes {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }
        &_body {
          width: 100%;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
        &_subtitle {
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
      &--open {
        max-height: 30rem;
        &.filter__group_body--open-xl {
          max-height: 100%;
        }
      }
      &-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        div {
          flex: 1;
          margin-top: 0;
          // @media screen and (max-width: $xs) {
          //   width: 100%;
          //   max-width: 200px;
          // }
        }
      }
    }
  }
  &--mobile-full {
    .filter {
      &__header {
        @media screen and (max-width: $xs) {
          width: 100%;
        }
      }
    }
  }
}
</style>

